#contactpage {
    max-width: 300px;
    margin: 0 auto;
    h1 {
        font-family: 'flood';
        line-height: 1;
        font-size: 3em;
        padding: 10px;
        margin: 0;
        text-align: center; }
    p {
        margin: 0;
        padding: 0 10px 10px 10px;
        color: rgb(139, 139, 139);
        font-style: italic;
        text-align: center;
        margin-bottom: 20px; }
    .contact-list {
        .contact-row {
            .contact-item {
                display: flex;
                align-items: center;
                padding: 10px;
                font-family: 'Ubuntu Condensed';
                a {
                    &:hover {
                        font-weight: 900;
                        transition: all 0.1s; } }
                .icon {
                    margin-right: 15px;
                    .MuiSvgIcon-root {
                        font-size: 3em; } } } } } }
