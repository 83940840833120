@import url(https://fonts.googleapis.com/css?family=Bungee|Ubuntu|Ubuntu+Condensed&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: flood;
  src: url(/static/media/flood.07b0fe90.ttf); }

@font-face {
  font-family: heiseikaku;
  src: url(/static/media/heiseikaku.95cda9ea.otf); }

body {
  padding: 8px; }

a {
  color: #000;
  color: initial;
  text-decoration: none; }

#app {
  font-family: Roboto;
  display: flex;
  flex-direction: column; }
  #app .page-container {
    padding-top: 20px; }
    @media screen and (max-width: 550px) {
      #app .page-container {
        padding-top: 0; } }

#navbar {
  display: flex;
  align-items: center; }
  @media screen and (max-width: 550px) {
    #navbar {
      flex-direction: column; } }
  #navbar .navbar-title-container {
    flex-grow: 1; }
    @media screen and (max-width: 550px) {
      #navbar .navbar-title-container {
        text-align: center; } }
    #navbar .navbar-title-container a {
      display: inline-block; }
    #navbar .navbar-title-container h1 {
      margin: 0;
      font-family: 'Bungee', cursive;
      font-size: 45px;
      line-height: 1;
      color: black;
      display: inline; }
    #navbar .navbar-title-container h3 {
      margin: 0;
      font-weight: 400;
      font-family: 'Ubuntu Condensed', san-serif;
      font-size: 15px;
      color: black; }
  #navbar .navbar-link-container {
    -webkit-transition: all 0.5s;
    transition: all 0.5s; }
    #navbar .navbar-link-container ul {
      display: flex;
      align-items: center;
      padding: 0;
      list-style: none; }
      #navbar .navbar-link-container ul a {
        color: black;
        margin: 0 5px;
        padding: 8px 15px;
        font-family: heiseikaku;
        font-weight: bold;
        line-height: 1;
        font-size: 12px;
        -webkit-transition: background 0.3s;
        transition: background 0.3s; }
        #navbar .navbar-link-container ul a:hover {
          background-color: whitesmoke; }
        #navbar .navbar-link-container ul a.active {
          background-color: black;
          color: white; }
  @media screen and (max-width: 550px) {
    #navbar.fixed .navbar-title-container {
      padding-bottom: 60px; } }
  @media screen and (max-width: 550px) {
    #navbar.fixed .navbar-link-container {
      position: fixed;
      background-color: white;
      top: 0px;
      left: 0;
      width: 100%;
      box-shadow: 0 0 3px 0px whitesmoke;
      z-index: 100; }
      #navbar.fixed .navbar-link-container ul {
        width: 320px;
        height: 60px;
        margin: 0 auto; } }

#homepage .image-container {
  width: 800px;
  height: 375px;
  display: block;
  margin: 20px auto 0 auto; }
  @media screen and (max-width: 800px) {
    #homepage .image-container {
      width: 100%;
      height: auto; } }
  @media screen and (max-width: 550px) {
    #homepage .image-container {
      width: 100%;
      height: auto; } }
  #homepage .image-container img {
    width: 100%; }

#homepage .introduction {
  width: 320px;
  display: block;
  margin: 0 auto; }
  @media screen and (max-width: 550px) {
    #homepage .introduction {
      width: 100%; } }
  #homepage .introduction h1 {
    font-family: flood;
    font-size: 50px;
    margin-bottom: 10px;
    line-height: 1;
    text-align: center; }
  #homepage .introduction p {
    color: #8b8b8b;
    text-align: center; }

#homepage .brand-list {
  display: flex;
  flex-wrap: wrap;
  width: 550px;
  margin: 0 auto;
  align-items: center;
  justify-content: center; }
  @media screen and (max-width: 550px) {
    #homepage .brand-list {
      width: 100%; } }
  #homepage .brand-list img {
    width: 100px;
    margin: 0 10px; }

#aboutpage .mobile-flex-column-reverse {
  max-width: 1200px;
  margin: 0 auto; }
  @media screen and (max-width: 550px) {
    #aboutpage .mobile-flex-column-reverse {
      display: flex;
      flex-direction: column-reverse; } }

#aboutpage .features-list {
  display: flex;
  flex-wrap: wrap; }
  #aboutpage .features-list .features-container {
    flex: 0 0 25%;
    box-sizing: border-box;
    padding: 10px; }
    @media screen and (max-width: 790px) {
      #aboutpage .features-list .features-container {
        flex: 0 0 50%;
        padding-bottom: 0;
        padding-top: 0; } }
    #aboutpage .features-list .features-container .feature-item {
      text-align: center; }
      #aboutpage .features-list .features-container .feature-item .feature-icon .MuiSvgIcon-root {
        font-size: 5.5em; }
      #aboutpage .features-list .features-container .feature-item .feature-description {
        margin-top: 10px; }
        #aboutpage .features-list .features-container .feature-item .feature-description h2 {
          margin: 0;
          font-size: 1.5em;
          font-family: 'flood';
          line-height: 1; }
          @media screen and (max-width: 550px) {
            #aboutpage .features-list .features-container .feature-item .feature-description h2 {
              font-size: 1em; } }
        #aboutpage .features-list .features-container .feature-item .feature-description p {
          margin: 5px 0 0 0;
          color: #8b8b8b;
          padding: 0 5px 20px 5px;
          font-family: 'Ubuntu';
          letter-spacing: 1.2px;
          line-height: 1.5;
          font-size: 0.8em; }
          @media screen and (max-width: 550px) {
            #aboutpage .features-list .features-container .feature-item .feature-description p {
              padding-bottom: 5px;
              font-size: 0.5em; } }

#aboutpage .row-container {
  display: flex; }
  @media screen and (max-width: 550px) {
    #aboutpage .row-container {
      flex-direction: column; } }
  #aboutpage .row-container .skill-lists {
    flex: 1 0 60%;
    padding: 10px;
    box-sizing: border-box; }
    @media screen and (max-width: 550px) {
      #aboutpage .row-container .skill-lists {
        flex: 1 1; } }
    #aboutpage .row-container .skill-lists .skill-container {
      margin-bottom: 10px; }
      #aboutpage .row-container .skill-lists .skill-container .skill-item {
        display: flex;
        position: relative;
        background-color: whitesmoke;
        box-shadow: 0 0 0.5px 0px dimgrey; }
        #aboutpage .row-container .skill-lists .skill-container .skill-item .skill-bar-name-container {
          flex: 0 0 20%;
          max-width: 107px;
          background-color: black;
          color: white;
          font-weight: bold;
          padding: 8px 20px;
          font-family: 'Ubuntu Condensed';
          line-height: 1; }
        #aboutpage .row-container .skill-lists .skill-container .skill-item .skill-bar-progress-container {
          flex-grow: 1;
          text-align: right; }
          #aboutpage .row-container .skill-lists .skill-container .skill-item .skill-bar-progress-container .skill-bar-progress {
            background-color: lightgray;
            height: 100%;
            width: 0;
            -webkit-transition: all 1s;
            transition: all 1s; }
        #aboutpage .row-container .skill-lists .skill-container .skill-item .skill-score {
          position: absolute;
          right: 10px;
          top: 6px;
          font-family: 'Ubuntu Condensed';
          color: darkslategray; }
  #aboutpage .row-container .profile-container {
    flex: 1 0 40%;
    padding: 10px;
    box-sizing: border-box; }
    @media screen and (max-width: 550px) {
      #aboutpage .row-container .profile-container {
        flex: 1 1; } }
    #aboutpage .row-container .profile-container .profile-content .image-container {
      width: 250px;
      display: block;
      margin: 0 auto; }
      @media screen and (max-width: 750px) {
        #aboutpage .row-container .profile-container .profile-content .image-container {
          width: 100%; } }
      #aboutpage .row-container .profile-container .profile-content .image-container img {
        width: 100%;
        box-shadow: 0 0 1px 1px darkgrey; }
    #aboutpage .row-container .profile-container .profile-content .description-container {
      width: 350px;
      text-align: center;
      display: block;
      margin: 0 auto; }
      @media screen and (max-width: 830px) {
        #aboutpage .row-container .profile-container .profile-content .description-container {
          width: 100%; } }
      #aboutpage .row-container .profile-container .profile-content .description-container h1 {
        margin: 0;
        font-family: 'Roboto';
        font-size: 1.5em; }
      #aboutpage .row-container .profile-container .profile-content .description-container h2 {
        margin: 0;
        font-family: 'Ubuntu Condensed';
        color: #8b8b8b;
        font-size: 0.7em;
        margin-bottom: 15px;
        line-height: 1;
        font-weight: 100; }
      #aboutpage .row-container .profile-container .profile-content .description-container p {
        margin: 0;
        font-family: 'Ubuntu';
        letter-spacing: 1.1px;
        line-height: 1.4;
        font-size: 0.7em; }

#portfolio-item-list {
  position: relative; }
  #portfolio-item-list h1 {
    font-family: 'flood';
    line-height: 1;
    font-size: 60px;
    text-align: center;
    margin: 0 0 20px 0; }
    @media screen and (max-width: 550px) {
      #portfolio-item-list h1 {
        margin-bottom: 0; } }
  #portfolio-item-list .portfolio-item-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1200px;
    margin: 0 auto; }
    #portfolio-item-list .portfolio-item-list .portfolio-item {
      padding: 10px 10px 10px 0;
      flex: 0 0 33.33%;
      box-sizing: border-box;
      margin-bottom: 20px;
      -webkit-transition: all 0.8s;
      transition: all 0.8s; }
      @media screen and (max-width: 850px) {
        #portfolio-item-list .portfolio-item-list .portfolio-item {
          flex: 0 0 50%;
          -webkit-filter: none;
                  filter: none; } }
      @media screen and (max-width: 550px) {
        #portfolio-item-list .portfolio-item-list .portfolio-item {
          flex: 0 0 100%; } }
      #portfolio-item-list .portfolio-item-list .portfolio-item .portfolio-item-content {
        position: relative;
        box-shadow: 0 0 0px 1px lightgrey;
        padding-bottom: 10px;
        height: 100%; }
        #portfolio-item-list .portfolio-item-list .portfolio-item .portfolio-item-content .portfolio-image-container {
          font-size: 0; }
          #portfolio-item-list .portfolio-item-list .portfolio-item .portfolio-item-content .portfolio-image-container img {
            width: 100%;
            -webkit-transition: all 0.5s;
            transition: all 0.5s; }
        #portfolio-item-list .portfolio-item-list .portfolio-item .portfolio-item-content .portfolio-title-container {
          padding-bottom: 20px;
          padding: 0 10px 0px 10px; }
          #portfolio-item-list .portfolio-item-list .portfolio-item .portfolio-item-content .portfolio-title-container h2 {
            line-height: 1;
            font-weight: 900;
            margin: 10px 0 5px 0; }
          #portfolio-item-list .portfolio-item-list .portfolio-item .portfolio-item-content .portfolio-title-container .subtitle {
            margin: 0;
            font-family: 'Ubuntu Condensed';
            line-height: 1;
            color: #8b8b8b;
            border-bottom: 0.5px solid lightgray;
            padding-bottom: 10px; }
        #portfolio-item-list .portfolio-item-list .portfolio-item .portfolio-item-content .portfolio-item-description {
          color: dimgray;
          font-family: 'Ubuntu';
          line-height: 1.5;
          padding: 10px 10px 40px 10px; }
          #portfolio-item-list .portfolio-item-list .portfolio-item .portfolio-item-content .portfolio-item-description p {
            margin: 0;
            color: black;
            padding-bottom: 20px;
            letter-spacing: 1.2px; }
          #portfolio-item-list .portfolio-item-list .portfolio-item .portfolio-item-content .portfolio-item-description .portfolio-app-icon-container .ios-link {
            margin-right: 10px; }
        #portfolio-item-list .portfolio-item-list .portfolio-item .portfolio-item-content .portfolio-cta {
          background-color: black;
          color: white;
          font-family: 'Ubuntu Condensed';
          line-height: 1;
          padding: 10px 20px;
          margin-left: 10px;
          display: inline-block;
          cursor: pointer;
          -webkit-transition: -webkit-transform 0.1s;
          transition: -webkit-transform 0.1s;
          transition: transform 0.1s;
          transition: transform 0.1s, -webkit-transform 0.1s;
          position: absolute;
          bottom: 10px; }
          #portfolio-item-list .portfolio-item-list .portfolio-item .portfolio-item-content .portfolio-cta:hover {
            -webkit-transform: scale(1.05);
                    transform: scale(1.05); }

#contactpage {
  max-width: 300px;
  margin: 0 auto; }
  #contactpage h1 {
    font-family: 'flood';
    line-height: 1;
    font-size: 3em;
    padding: 10px;
    margin: 0;
    text-align: center; }
  #contactpage p {
    margin: 0;
    padding: 0 10px 10px 10px;
    color: #8b8b8b;
    font-style: italic;
    text-align: center;
    margin-bottom: 20px; }
  #contactpage .contact-list .contact-row .contact-item {
    display: flex;
    align-items: center;
    padding: 10px;
    font-family: 'Ubuntu Condensed'; }
    #contactpage .contact-list .contact-row .contact-item a:hover {
      font-weight: 900;
      -webkit-transition: all 0.1s;
      transition: all 0.1s; }
    #contactpage .contact-list .contact-row .contact-item .icon {
      margin-right: 15px; }
      #contactpage .contact-list .contact-row .contact-item .icon .MuiSvgIcon-root {
        font-size: 3em; }

