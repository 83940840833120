#navbar {
  display: flex;
  align-items: center;
  @media screen and (max-width: 550px) {
    flex-direction: column; }
  .navbar-title-container {
    flex-grow: 1;
    @media screen and (max-width: 550px) {
      text-align: center; }
    a {
      display: inline-block; }
    h1 {
      margin: 0;
      font-family: 'Bungee', cursive;
      font-size: 45px;
      line-height: 1;
      color: black;
      display: inline; }
    h3 {
      margin: 0;
      font-weight: 400;
      font-family: 'Ubuntu Condensed', san-serif;
      font-size: 15px;
      color: black; } }
  .navbar-link-container {
    transition: all 0.5s;
    ul {
      display: flex;
      align-items: center;
      padding: 0;
      list-style: none;
      // transition: all 0.5s
      a {
        color: black;
        margin: 0 5px;
        padding: 8px 15px;
        font-family: heiseikaku;
        font-weight: bold;
        line-height: 1;
        font-size: 12px;
        transition: background 0.3s;
        &:hover {
          background-color: rgb(245, 245, 245); }
        &.active {
          background-color: black;
          color: white; } } } }

  &.fixed {
    .navbar-title-container {
      @media screen and (max-width: 550px) {
        padding-bottom: 60px; } }

    .navbar-link-container {
      @media screen and (max-width: 550px) {
        position: fixed;
        background-color: white;
        top: 0px;
        left: 0;
        width: 100%;
        box-shadow: 0 0 3px 0px rgb(245, 245, 245);
        z-index: 100;
        ul {
          width: 320px;
          height: 60px;
          margin: 0 auto; } } } } }

