#homepage {
    .image-container {
        width: 800px;
        height: 375px;
        display: block;
        margin: 20px auto 0 auto;
        @media screen and (max-width: 800px) {
            width: 100%;
            height: auto; }
        @media screen and (max-width: 550px) {
            width: 100%;
            height: auto; }
        img {
            width: 100%; } }
    .introduction {
        width: 320px;
        display: block;
        margin: 0 auto;
        @media screen and (max-width: 550px) {
            width: 100%; }
        h1 {
            font-family: flood;
            font-size: 50px;
            margin-bottom: 10px;
            line-height: 1;
            text-align: center; }
        p {
            color: rgb(139, 139, 139);
            text-align: center; } }
    .brand-list {
        display: flex;
        flex-wrap: wrap;
        width: 550px;
        margin: 0 auto;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: 550px) {
            width: 100%; }
        img {
            width: 100px;
            margin: 0 10px; } } }
