@import './variables.sass';
@import url('https://fonts.googleapis.com/css?family=Bungee|Ubuntu|Ubuntu+Condensed&display=swap');

// font-family: 'Ubuntu', sans-serif
// font-family: 'Ubuntu Condensed', sans-serif
// font-family: 'Bungee', cursive

@font-face {
  font-family: flood;
  src: url('./assets/fonts/flood.ttf'); }

@font-face {
  font-family: heiseikaku;
  src: url('./assets/fonts/heiseikaku.otf'); }

body {
  padding: 8px; }

a {
  color: initial;
  text-decoration: none; }

#app {
  font-family: Roboto;
  display: flex;
  flex-direction: column;
  .page-container {
    padding-top: 20px;
    @media screen and (max-width: 550px) {
      padding-top: 0; } } }
